import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Menu } from "./components/menu/Menu";
import { LogIn } from "./components/menu/Login";
import { SignIn } from "./components/menu/SignIn";
import "./scss/main.scss";
import { LeftContainer } from "./components/left_container/LeftContainer";
import { RightContainer } from "./components/right_container/RightContainer";
import { Faq } from "./components/faq/faq";
import { ResetPassword } from "./components/menu/ResetPassword";
import { Fetcher } from "./components/reusables/Fetcher";
import Loader from "react-loader";

class App extends Component {
  state = {
    data: [],
    logged: true,
    step: 1,
    loader: true
  };

  componentDidMount() {
    if (sessionStorage.getItem('casala')) {
      const user = sessionStorage.getItem('casala')
      this.setState({
        user: JSON.parse(user).user
      },
        () => this.getData())
    } else {
      this.setState({ logged: false })
    }
  }

  getData = () => {

    // fetch("http://localhost:8000/django/logo/", {
    fetch("/django/logo/", {
    // fetch("/django/logo/", {
      method: "GET",
      credentials: 'include',
      headers: {
        "Authorization": `Token ${this.state.token}`
      }
    })
      .then(res => res.json())
      .then(resp => this.setState({ data: resp }))
  };

  loginHandler = login_data => {

    this.setState({
      user: login_data.user,
      password: login_data.password,
      loader: false
    }, () => {
      const auth = {
        "username": this.state.user,
        "password": this.state.password
      }

      // fetch("http://localhost:8000/django/csrftoken")
      fetch("/django/csrftoken/")
        .then(res => res.json())
        .then(resp => {

          Fetcher("/django/login/", JSON.stringify(auth), resp.token)
            .then((res) => res.json())
            .then((resp) => {
              this.setState({ token: resp.token })
              if (resp.msg.toString() === "User couldn't log in") throw new Error()
              else {
                this.setState({
                  logged: true
                })
                const casala = { "user": this.state.user }
                sessionStorage.setItem('casala', JSON.stringify(casala))
              }
            })
            .then(() => this.getData())
            .then(() => this.props.history.push("/"))
            .catch(() => {
              this.setState({
                user: "",
                password: ""
              })
              alert("Wrong username or password")
            })
            .finally(() => this.setState({ loader: true }))
        })
    })
  };

  signinHandler = signin_data => {
    this.setState({ loader: false })
    Fetcher("/django/register/", JSON.stringify(signin_data))
      .then((res) => res.json())
      .then(() => alert("User created"))
      .then(() => this.props.history.push("/login"))
      .finally(() => this.setState({ loader: true }))
  };

  logout = () => {
    sessionStorage.removeItem('casala')
    this.setState({ logged: false })
  }

  resetPasswordHandler = validated_data => {
    this.setState({ loader: false })
    Fetcher("/django/resetpassword/", JSON.stringify(validated_data))
      .then((res) => res.json())
      .then(resp => {
        if (resp.msg.toString() === "Permitted to edit") {
          this.setState({ step: this.state.step + 1 })
        }
        else {
          alert("User doesn't exist")
        }
      })
      .finally(() => this.setState({ loader: true }))
  }

  changePasswordHandler = changed_password => {
    this.setState({ loader: false })
    Fetcher("/django/changepassword/", JSON.stringify(changed_password))
      .then((res) => res.json())
      .then(resp => {
        alert(resp.msg)
        this.setState({ step: 1 })
      })
      .then(() => this.props.history.push("/login"))
      .finally(() => this.setState({ loader: true }))
  }

  handleLoader = () => this.setState({loader: !this.state.loader})


  render() {
    return (
      <div className="container">
        {Menu(this.state.logged, this.logout, this.state.user)}
         <Loader
          loaded={this.state.loader}
          lines={13}
          length={20}
          width={10}
          radius={30}
          // zIndex={2e9}
          top="50%"
          left="50%"
          // loadedClassName="loadedContent"
        />

        <div className="content">
          {this.state.logged ? (
            <Route exact path="/">
              {LeftContainer(this.state.data)}

              <RightContainer data={this.state.data} getData={this.getData} csrfToken={this.state.csrfToken} handleLoader={this.handleLoader} />
            </Route>
          ) : (
              <Redirect to="/login" />
            )
          }

          <Route path="/faq">{Faq()}</Route>
          <Route path="/login">
            <LogIn loginHandler={this.loginHandler} />
          </Route>
          <Route path="/signin">
            <SignIn signinHandler={this.signinHandler} />
          </Route>
          <Route path="/changepassword">
            <ResetPassword
              resetPasswordHandler={this.resetPasswordHandler}
              changePasswordHandler={this.changePasswordHandler}
              step={this.state.step} />
          </Route>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
