export const Validator = (pass, email) => {

  const errors = []
  const containsDigit = /[0-9]/.test(pass);
  const containsUpper = /[A-Z]/.test(pass);
  const containsLower = /[a-z]/.test(pass);
  const passwordLength = pass.length > 7;
  const validateEmail = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email);


  if (!containsDigit || !containsUpper || !containsLower || !passwordLength || !validateEmail) {
    if (email) {
      if (!validateEmail) errors.push("Email is incorrect")
    }

    if (!containsDigit) errors.push("Password has to containt at least one number")

    if (!containsUpper) errors.push("Password has to containt at least one big letter")

    if (!containsLower) errors.push("Password has to containt at least one small letter")

    if (!passwordLength) errors.push("Password has to have at least 8 characters")
  }
  return errors
}
