import React from "react";

export const Faq = () => (
  <div className="faq_container">
    <h1>Logo Converter</h1>
    <h2>How to use</h2>
    <h3><span className="important">Important:</span> Remember that logo has to be white on black background for proper conversion</h3>
    {/* <h3><span className="important">Important:</span> All images need to have .png extensions</h3> */}
    <p>1. Click on drop field and choose image or simply drag desired image to the field</p>
    <p>2. Follow 4 steps of scaling image:</p>
    <div>
      <p>a) Image which will be shown in Logo Library below the DropField and in the preview of mobile app</p>
      <p>b) Image which will be displayed as a big logo in mobile app</p>
      <p>c) Image which will be displayed as a small logo in mobile app - can be skipped</p>
      <p>d) Preview of all logos and confirmation</p>
    </div>
    <p>3. Log in to your account on mobile application and click on <span style={{ fontStyle: "italic" }}>SYNCHRONIZE LOGO</span></p>
    <p>4. To delete image - click on <span style={{ fontStyle: "italic" }}>Delete logo</span> button overflowing the logo</p>
  </div>
);
