import React from "react";

export const LeftContainer = data => {
  return (
    <div id="left_container">
      <h1>Casala</h1>
      <div className="general_info">
        <h3>General Info</h3>
        <p className="info">
          <span>Logo Preview Dimension:</span>
          <span>64 x 64</span>
        </p>
        <p className="info">
          <span>Big Logo Dimension:</span>
          <span>144 x 144</span>
        </p>
        <p className="info">
          <span>Small Logo Dimension:</span>
          <span>88 x 40</span>
        </p>
        <p className="info">
          <span>Amount of logos:</span>
          <span>{data.length ? data.length : 0}</span>
        </p>

        <div className="last_logo">
          <h3>Last uploaded logo</h3>
          {data.length ? (
            <div>
              <img
                src={data.length && "data:image/png;base64," + data[data.length - 1].thumbL[0]}
                alt="last_image_uploaded"
              />
              <img
                src={data.length && "data:image/png;base64," + data[data.length - 1].thumbL[1]}
                alt="last_image_uploaded"
              />
            </div>
          ) : (
              <div>no data</div>
            )}
        </div>
      </div>
    </div>
  );
};
