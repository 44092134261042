import React from "react";
import Dropzone from "react-dropzone";
import upload from "../../assets/upload_icon.png";


export const DropFile = (onDrop) => {
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} accept="image/x-png" />
            <img src={upload} alt="upload_file" />
            <p style={{ marginTop: 10 }}>
              Drop your image here, or click to select files to upload.
            </p>
          </div>
        );
      }}
    </Dropzone>
  );
};
