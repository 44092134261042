import React, { Component } from "react";
import { DropFile } from "./DropFile";
import { LogoLibrary } from "./LogoLibrary";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.min.css";
import { Fetcher } from '../reusables/Fetcher'
import Cookies from 'js-cookie';

export class RightContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: 1
    };
  }

  sendPreview() {

    const form_data = new FormData()
    form_data.append('name', this.state.name.split('.').slice(0, -1).join('.'))
    form_data.append('thumb', this.state.thumb)
    form_data.append('thumbL', this.state.thumbL)

    this.state.thumbS && form_data.append('thumbS', this.state.thumbS)

    this.props.handleLoader();

    Fetcher("/django/logo/", form_data)
      .then(res => res.json())
      .then((resp) => this.setState({ id: resp.id }))
      .then(() => this.props.getData())
      .then(() => this.setState({ phase: 5 }))
      .finally(() => this.props.handleLoader())
  }

  deleteData = (id) => {
    const csrfToken = Cookies.get('csrftoken');
    this.props.handleLoader();
    // fetch("/logo/?id=" + id, {
    // fetch("http://localhost:8000/django/logo/?id=" + id, {
     fetch("/django/logo/?id=" + id, {
      method: "DELETE",
      credentials: 'include',
      headers: {
          "X-CSRFToken": csrfToken,
      }
    })
      .then(() => this.props.getData())
      .finally(() => this.props.handleLoader())
  };

  onDrop = file => {
    const logo = file[0].name;
    const ext = logo.split(".").pop();
    const extensions = ["bmp", "png", "jpg", "jpeg", "gif"];
    if (extensions.indexOf(ext) === -1) {
      alert('Format of logo you have chosen is not supported')
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file[0])

      reader.onloadend = () => {
        const preview = reader.result
        this.setState({
          name: file[0].name,
          preview: preview,
          phase: 2
        });
      }
    }
  };

  onCropSave = (condition) => {
    if (condition === "big") {
      let croppedBigLogo = this.refs.cropper.getCroppedCanvas({ width: 64, height: 64 }).toDataURL();
      this.setState({ thumb: croppedBigLogo, phase: 3 });
    }
    else if (condition === "small") {
      let croppedSmallLogo = this.refs.cropper.getCroppedCanvas({ width: 144, height: 144 }).toDataURL();
      this.setState({ thumbL: croppedSmallLogo, phase: 4 })
    }
    else if (condition === "very_small") {
      let croppedVerySmallLogo = this.refs.cropper.getCroppedCanvas({ width: 88, height: 40 }).toDataURL();
      this.setState({ thumbS: croppedVerySmallLogo },
        () => this.sendPreview()
      )
    }
  }

  submitData() {
    alert("Logo was succesfully added to database")
    this.clearAndCancel()
  }

  clearAndCancel() {
    this.setState({
      phase: 1,
      preview: null,
      thumb: null,
      thumbL: null,
      thumbS: null
    })
  }

  cancel() {
    this.deleteData(this.state.id)
    setTimeout(() => this.clearAndCancel(), 300)
  }


  render() {
    const { data } = this.props
    let panel;

    // eslint-disable-next-line default-case
    switch (this.state.phase) {
      case 1:
        panel = (
          <>
            <h2>Upload logo</h2>
            {DropFile(this.onDrop)}
            <h2>Logo Library</h2>
            {LogoLibrary(this.props.data, this.deleteData)}
          </>
        );
        break;

      case 2:
        panel = (
          <>
            <h2>Customize big logo</h2>
            <Cropper
              ref="cropper"
              src={this.state.preview}
              aspectRatio={1}
              style={{ height: 450 }}
            />
            <button
              className="btn btn-success"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.onCropSave("big")}
            >
              Save and continue
            </button>
            <button
              className="btn btn-failed"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.clearAndCancel()}
            >
              Cancel
            </button>
          </>
        );
        break;

      case 3:
        panel = (
          <>
            <h2>Customize small logo</h2>
            <Cropper
              ref="cropper"
              src={this.state.preview}
              aspectRatio={1}
              style={{ height: 450 }}
            />
            <button
              className="btn btn-success"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.onCropSave("small")}
            >
              Save
            </button>
            <button
              className="btn btn-failed"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.clearAndCancel()}
            >
              Cancel
            </button>
          </>
        );
        break;

      case 4:
        panel = (
          <>
            <h2>Customize very small logo</h2>
            <Cropper
              ref="cropper"
              src={this.state.preview}
              aspectRatio={2 / 1}
              style={{ height: 450 }}
            />
            <button
              className="btn btn-success"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.onCropSave("very_small")}
            >
              Save
            </button>
            <button
              className="btn btn-failed"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.clearAndCancel()}
            >
              Cancel
            </button>
            <button
              className="btn btn-skip"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.sendPreview()}
            >
              Skip small logos
            </button>
          </>
        );
        break;

      case 5:
        panel = (
          <>
            <h2>Preview of logos</h2>
            <h3>Preview</h3>
            {data.length &&
              data[data.length - 1].id === this.state.id
              ? <div className="final_preview">
                <img className="preview" src={"data:image/png;base64," + data[data.length - 1].thumb[0]} alt="small_logo" />
                <img className="preview" src={"data:image/png;base64," + data[data.length - 1].thumb[1]} alt="small_logo" />
              </div>
              : <p className="processing">Please wait processing...</p>
            }
            <hr />

            <h3>Big logo</h3>
            {data.length &&
              data[data.length - 1].id === this.state.id
              ? <div className="final_preview">
                <img className="big_logo_preview" src={"data:image/png;base64," + data[data.length - 1].thumbL[0]} alt="small_logo" />
                <img className="big_logo_preview" src={"data:image/png;base64," + data[data.length - 1].thumbL[1]} alt="small_logo" />
              </div>
              : <p className="processing">Please wait processing...</p>
            }
            <hr />


            {this.state.thumbS &&
              <>
                <h3>Small logo</h3>
                {data.length &&
                  data[data.length - 1].id === this.state.id
                  ? <div className="final_preview">
                    <img className="small_logo_preview" src={"data:image/png;base64," + data[data.length - 1].thumbS[0]} alt="small_logo" />
                    <img className="small_logo_preview" src={"data:image/png;base64," + data[data.length - 1].thumbS[1]} alt="small_logo" />
                  </div>
                  : <p className="processing">Please wait processing...</p>
                }

              </>
            }

            <button
              className="btn btn-success"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.submitData()}
            >
              Save
              </button>
            <button
              className="btn btn-failed"
              title="Save image in media library and publish it to Casala Converter."
              onClick={() => this.cancel()}
            >
              Cancel
              </button>
          </>
        );
        break;
    }

    return <div id="right_container">{panel}</div>;
  }
}
