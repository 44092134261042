import React from "react";

export const LogoLibrary = (tests, deleteData) => {
  return (
    <div className="logo_library">
      {tests.length ? (
        tests.map((el, i) => (
          <div className="logo" key={i}>
            <div>
              <div className="logo_wrapper">
                <img src={"data:image/png;base64," + el.thumbL[0]} alt="Logo" />
              </div>

              <div className="logo_wrapper_second">
                {el.thumbS
                  ? <img src={"data:image/png;base64," + el.thumbS[0]} alt="Logo" />
                  : <p className="no_logo">No small logo avaialble</p>
                }
                <p className="logo_number">1</p>
              </div>

            </div>
            <div>
              <div className="logo_wrapper inverted">
                <img src={"data:image/png;base64," + el.thumbL[1]} alt="Logo" />
              </div>

              <div className="logo_wrapper_second inverted">
                {el.thumbS
                  ? <img src={"data:image/png;base64," + el.thumbS[1]} alt="Logo" />
                  : <p className="no_logo">No small logo avaialble</p>
                }
                <p className="logo_number">1</p>
              </div>

              <span className="logo_description">{el.name}</span>
              <button className="logo_delete" onClick={() => deleteData(el.id)}>
                Delete logo
              </button>
            </div>
          </div>
        ))
      ) : (
          <div className="no_library_data">There is no logo in database</div>
        )}
    </div>
  );
};
