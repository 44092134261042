import React, { Component } from "react";
import { Link } from "react-router-dom";


export class LogIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: "",
      password: "",
    }
  }
  submit = () => {
    this.props.loginHandler(this.state)
  }
  userHandler = (e) => {
    this.setState({ user: e.target.value })
  }
  passwordHandler = (e) => {
    this.setState({ password: e.target.value })
  }
  render() {

    return (
      <div className="log_panel">
        <h1>Please log in</h1>
        <div>
          <label>
            User:
            <input
              type="text"
              name="user"
              value={this.state.user}
              onChange={e => this.userHandler(e)}
            />
          </label>

          <label>
            Password:
            <input
              type="password"
              name="password"
              value={this.state.password}
              onChange={e => this.passwordHandler(e)}
            />
          </label>

          <button className="submit_btn" onClick={e => this.submit(e)}>Log in</button>
          <p>Forgot password? Click
            <span className="resetPassword"> <Link to="/changepassword">here</Link> </span>
            to reset it
          </p>

        </div>
      </div>
    );
  }
}

