import React, { Component } from "react";
import { Validator } from "../reusables/Validator";

export class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: "",
      email: "",
      password: "",
      password_confirmation: "",
      errors: []
    }
  }
  submit = () => {
    this.setState({ errors: [] })
    if (this.state.password === this.state.password_confirmation) {
      const { errors, password_confirmation, ...credentials } = this.state;
      const error_list = Validator(this.state.password, this.state.email)

      if (error_list.length) this.setState({ errors: error_list })
      else this.props.signinHandler(credentials)
    }
    else {
      this.setState({ errors: ["Both passwords are not the same"] })
    }
  }

  userHandler = (e) => {
    this.setState({ user: e.target.value })
  }
  emailHandler = (e) => {
    this.setState({ email: e.target.value })
  }
  passwordHandler = (e) => {
    this.setState({ password: e.target.value })
  }
  passwordConfirmationHandler = (e) => {
    this.setState({ password_confirmation: e.target.value })
  }
  
  render() {

    return (
      <div className="log_panel">
        <h1>Please sign up</h1>
        <div>
          <label>
            User:
            <input
              type="text"
              name="user"
              value={this.state.user}
              onChange={e => this.userHandler(e)}
            />
          </label>

          <label>
            Email:
            <input
              type="email"
              name="email"
              value={this.state.email}
              onChange={e => this.emailHandler(e)}
            />
          </label>

          <label>
            Password:
            <input
              type="password"
              name="password"
              value={this.state.password}
              onChange={e => this.passwordHandler(e)}
            />
          </label>

          <label>
            Confirm: 
            <input
              type="password"
              name="confirmation_password"
              value={this.state.confirmation_password}
              onChange={e => this.passwordConfirmationHandler(e)}
            />
          </label>

          <button className="submit_btn" onClick={e => this.submit(e)}>Sign in</button>

          {this.state.errors.map((el, i) => <p className="errors" key={i}>{el}</p>)}

        </div>
      </div>
    );
  }
}
