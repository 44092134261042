import React, { Component } from "react";
import { Validator } from "../reusables/Validator";

export class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: "",
      email: "",
      password: "",
      password_confirmation: "",
      errors: [],
    }
  }
  // submit = () => {
  //   const {step, ...credentials} = this.state
  //   this.props.resetPasswordHandler(credentials)
  // }
  submit_and_authenticate = () => {
    const { password, password_confirmation, ...credentials } = this.state
    this.props.resetPasswordHandler(credentials)
  }
  submit_and_change_password = () => {
    this.setState({ errors: [] })
    const { password_confirmation, errors, ...credentials } = this.state
    const error_list = Validator(this.state.password)

    if (this.state.password === this.state.password_confirmation) {
      if (error_list.length) this.setState({ errors: error_list })
      else this.props.changePasswordHandler(credentials)
    }
    else {
      this.setState({ errors: ["Both passwords are not the same"] })
    }
  }


  userHandler = (e) => {
    this.setState({ user: e.target.value })
  }
  emailHandler = (e) => {
    this.setState({ email: e.target.value })
  }

  passwordHandler = (e) => {
    this.setState({ password: e.target.value })
  }
  passwordConfirmationHandler = (e) => {
    this.setState({ password_confirmation: e.target.value })
  }
  render() {
    let changePasswordForm;

    // eslint-disable-next-line default-case
    switch (this.props.step) {
      case 1:
        changePasswordForm =
          <div className="log_panel">
            <h1>Type your login and email to reset password</h1>
            <div>
              <label>
                User:
          <input
                  type="text"
                  name="user"
                  value={this.state.user}
                  onChange={e => this.userHandler(e)}
                />
              </label>

              <label>
                Email:
          <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={e => this.emailHandler(e)}
                />
              </label>

              <button className="submit_btn" onClick={e => this.submit_and_authenticate(e)}>Submit</button>

            </div>
          </div>
        break;

      case 2:
        changePasswordForm =
          <div className="log_panel">
            <h1>Please type your new password and confirm</h1>
            <div>
              <label>
                Password:
            <input
                  type="text"
                  name="password"
                  value={this.state.password}
                  onChange={e => this.passwordHandler(e)}
                />
              </label>

              <label>
                Confirm:
            <input
                  type="text"
                  name="passwordConfirmation"
                  value={this.state.password_confirmation}
                  onChange={e => this.passwordConfirmationHandler(e)}
                />
              </label>

              <button
                className="submit_btn"
                style={{ height: 60 }}
                onClick={e => this.submit_and_change_password(e)}>Change password</button>

              {this.state.errors.map((el, i) => <p className="errors" key={i}>{el}</p>)}

            </div>
          </div>
        break;
    }

    return changePasswordForm
  }
}

