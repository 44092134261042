import React from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

export const Menu = (logged, logout, user) => {
  const userName = user && user;
  return (
    <div id="menu">
      <div className="logo">
        {logged ? (
          <>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <p>Hello {userName}</p>
          </>
        ) : (
            <Link to="/login">
              <img src={logo} alt="logo" />
            </Link>
          )}
      </div>
      <div className="menu_panel">
        <ul>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          {logged ? (
            <li onClick={e => logout(e)}>Logout</li>
          ) : (
              <>
                <li>
                  <Link to="/login">Log in</Link>
                </li>
                <li>
                  <Link to="/signin">Sign in</Link>
                </li>
              </>
            )}
        </ul>
      </div>
    </div>
  );
};
