import Cookies from 'js-cookie';

export const Fetcher = (url, content, token) => {
    if (token) Cookies.set("csrftoken", token)
    const csrfToken = Cookies.get('csrftoken')
    
    // return fetch("http://localhost:8000" + url, {
    return fetch(url, {
    // return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/pain, */*',
            "X-CSRFToken": csrfToken,
        },
        body: content
    })
}
